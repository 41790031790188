import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 border-b pb-4" }
const _hoisted_4 = { class: "flex items-center text-xl font-semibold text-gray-900" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "font-medium text-gray-900" }
const _hoisted_7 = { class: "uppercase" }
const _hoisted_8 = { class: "text-sm font-medium uppercase text-gray-500" }
const _hoisted_9 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_10 = {
  key: 0,
  class: "text-sm font-medium uppercase text-gray-500 flex items-center"
}
const _hoisted_11 = { class: "text-sm font-medium uppercase text-gray-600 ml-1" }
const _hoisted_12 = { class: "font-medium text-gray-900" }
const _hoisted_13 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_14 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_15 = { class: "inline-block font-medium text-gray-900" }
const _hoisted_16 = { class: "text-sm font-medium capitalize text-gray-500" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.filtered ? _ctx.$t('platform.common.search-results') : _ctx.$t('platform.patient.all')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                placeholder: _ctx.$t('platform.common.search'),
                icon: "search",
                "icon-position": "left",
                "icon-height": "16px",
                size: "small",
                onKeyup: _ctx.updateSearchDebounced
              }, null, 8, ["modelValue", "placeholder", "onKeyup"]),
              _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                color: "primary",
                "data-cy": "search-patient-btn",
                to: "/patient-search",
                class: "ml-4",
                "left-icon": "add",
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.add-patient')), 1)
                ]),
                _: 1
              })), [
                [_directive_allow, 'external-lookup-patient:read']
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                "data-cy": "new-patient-btn",
                to: "/patients/new",
                class: "ml-4",
                "left-icon": "add",
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.new')), 1)
                ]),
                _: 1
              })), [
                [_directive_allow, 'patient:create']
              ])
            ])
          ]),
          _createVNode(_component_DataTable, {
            loading: _ctx.loading,
            columns: _ctx.showColumns(),
            rows: _ctx.rows,
            sort: _ctx.sort,
            class: "w-full mt-6",
            onSort: _ctx.updateSort
          }, {
            "cell.last_name": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(row.last_name) + ",", 1),
                _createTextVNode(" " + _toDisplayString(row.first_name), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.id')) + ": " + _toDisplayString(row.mrn) + " ", 1),
                (row.minor_patients && row.minor_patients.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createVNode(_component_MkBadge, {
                        color: "primary",
                        size: "xsmall",
                        class: "capitalize"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.patient.major-indicator')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (row.nhs)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_BaseIcon, { name: "nhs-logo" }),
                    _createElementVNode("div", _hoisted_11, " : " + _toDisplayString(row.nhs), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.date_of_birth": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.formatDob(row.date_of_birth)), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getSexOrGender(row)) + " (" + _toDisplayString(_ctx.$t('platform.patient.years', { age: row.age })) + ") ", 1)
            ]),
            "cell.last_activity": _withCtx(({ row }) => [
              (row.latestFormSubmission)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createTextVNode(_toDisplayString(row.latestFormSubmission.form.name) + " ", 1),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formatDateToLocale(row.latestFormSubmission.updated_at, 'long')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.deceased_at": _withCtx(({ row }) => [
              (row.deceased_at)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createVNode(_component_MkBadge, {
                      color: "gray",
                      size: "small",
                      filled: "",
                      label: 
                  _ctx.$t('custom.uhb.patient.deceased-at', {
                    deceasedDate: _ctx.formatDob(row.deceased_at)
                  })
                
                    }, null, 8, ["label"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.actions": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_ButtonLink, {
                  "data-cy": `view-patient-${row.id}-btn`,
                  to: `/patients/${row.id}`,
                  "right-icon": "chevron-right",
                  color: "ghost"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
                  ]),
                  _: 2
                }, 1032, ["data-cy", "to"]),
                _createVNode(_component_BasePopover, { position: "left" }, {
                  button: _withCtx(() => [
                    _createVNode(_component_SpecialityButton, { class: "ml-3" })
                  ]),
                  menu: _withCtx(() => [
                    _createVNode(_component_PopoverLink, {
                      to: '/patients/' + row.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.view')), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                      onClick: ($event: any) => (_ctx.showRemovePatientModal(row.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.remove')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_directive_allow, 'patient:delete']
                    ])
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 1
          }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
          (_ctx.total && _ctx.total > _ctx.perPage)
            ? (_openBlock(), _createBlock(_component_BasePagination, {
                key: 0,
                class: "mt-8",
                "model-value": _ctx.page,
                "page-size": _ctx.perPage,
                length: _ctx.total,
                "sm-pages-limit": 5,
                "md-pages-limit": 8,
                "items-name": _ctx.$t('platform.navigation.patients').toLowerCase(),
                "onUpdate:modelValue": _ctx.changePage
              }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.viewRemovePatientModal)
          ? (_openBlock(), _createBlock(_component_ActionModal, {
              key: 0,
              icon: "warning",
              "icon-color": "alert-400",
              title: _ctx.$t('platform.patient.remove'),
              body: _ctx.$t('platform.patient.remove-message'),
              actions: _ctx.removeModalActions,
              onClose: _ctx.closeRemovePatientModal
            }, null, 8, ["title", "body", "actions", "onClose"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}